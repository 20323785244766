import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useAppState } from '../../app-state';
import {NavLink, useHistory} from 'react-router-dom';
import { useCustomSnackbar } from '../../../common/hooks/custom-snackbars';
import Container from '@material-ui/core/Container';
import { ROUTES } from '../../constans';
import HomesGraph from '../../components/Сharts/HomesGraph';
import CirculationGraph from '../../components/Сharts/CirculationGraph';
import ValueGraph from '../../components/Сharts/ValueGraph';
import house from '../../../assets/img/houseHeader.svg';
import contentOne from '../../../assets/img/content-one.svg';
import contentTwo from '../../../assets/img/content-two.svg';
import contentThree from '../../../assets/img/content-three.svg';
import contentFour from '../../../assets/img/content-four.svg';
import {isValidated} from "../../util/helpers";
import useApi from '../../hooks/useApi';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatNum } from '../../../common/util';
import CoinButtons from "../../components/CoinButtons/CoinButtons";
import {COIN_TYPE} from "../../../common/constants";
import LandingHero from "../../components/LandingHero/LandingHero";
import SliderText from "../../components/SliderText/SliderText";
import { useStyles } from './style.js';
import HousesSvg from "../../components/HousesSvg/HousesSvg";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import ImgCard from "../../components/ImgCard/ImgCard";
import LargeTitle from "../../components/LargeTitle/LargeTitle";
import BlueLabel from "../../components/BlueLabel/BlueLabel";
import investorOne from '../../../assets/img/investor1.svg';
import investorTwo from '../../../assets/img/investor2.svg';
import investorThree from '../../../assets/img/investor3.svg';
import investorFour from '../../../assets/img/investor4.svg';
import investorFive from '../../../assets/img/investor5.svg';
import investorSix from '../../../assets/img/investor6.svg';
import investorSeven from '../../../assets/img/investor7.svg';
import sliderHouses from "../../../assets/img/sliderHauses.svg";
import sanFrancisco from "../../../assets/img/SanFrancisco.jpg";
import pressLogo from "../../../assets/img/pressLogo.svg";
import lamp from "../../../assets/img/lamp-logo.svg";
import graph from "../../../assets/img/LandingGraph.svg";
import pyramid from "../../../assets/img/pyramidHouse.svg";
import testimonials from "../../../assets/img/testimonials-logo.svg";
import TextCard from "../../components/TextCard/TextCard";
import CONFIG_COIN from "../../CoinConfig/CoinConfig";
import coinDesktopVideo from "../../../assets/video/coin-desktop.mp4";
import coinMobileVideo from "../../../assets/video/coin-mobile.mp4";
import CardGraph from "../../components/Сharts/CardGraph";
import DataItem from "../../../common/components/DataItem";

const HomePage = () => {
  const classes = useStyles();
  const {state, actions} = useAppState();
  const {showError, AUTH_ERRORS} = useCustomSnackbar();
  const [stats, setStats] = useState();
  const [houses, setHouses] = useState();
  const [circulation, setCirculation] = useState();
  const [valueGraph, setValueGraph] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();
  const history = useHistory();
  const [slidesArr, setSlidesArr] = useState(CONFIG_COIN);
  const [coinData, setCoinData] = useState();

  const coin = CONFIG_COIN.find(c => `${c.id}` === 'SEA');
  const [cardCoin, setCardCoin] = useState({
    ...coin,
    currentCirculation: 28,
    currentPrice: 17.9,
    price: [
      1.7,
      8.0,
      19.8,
      19.2,
      16.7,
      17.9,
    ],
    circulation: [
      4,
      16,
      35,
      33,
      29,
      28,
    ],
    dates: [
      "2019-12-01T00:00:00.000Z",
      "2020-12-01T00:00:00.000Z",
      "2021-12-01T00:00:00.000Z",
      "2022-12-01T00:00:00.000Z",
      "2023-12-01T00:00:00.000Z",
      "2024-12-01T00:00:00.000Z",
    ],
  });

  const buySellHandler = () => {
    if (isValidated(state.currentUser)) {
      actions.updateState({buySellActive: true});
    } else {
      history.push(ROUTES.SETTINGS);
    }
  };

  const signInHandler = () => {
    if (!state.isAuth) {
      history.push(window.location.pathname + '#login');
      // actions.updateState({modalSignInActive: true});
    } else {
      actions.updateState({buySellActive: true});
    }
  };

    return (
        <>
            <LandingHero
              buySellHandler={buySellHandler}
              signInHandler={signInHandler}
            />
            <section className={classes.landingGraph}>
              <ContentContainer>
                <BlueLabel
                  labelClassName={classes.landingGraphLabel}
                  text={"Fund Performance"}
                />
                <LargeTitle
                  titleClassName={classes.landingGraphBigTitle}
                  title={"Total Value Locked"}
                />
                <div className={classes.landingGraphText}>
                  Highlighting growth, the data displays total value locked capital from 2019 to 2024 alongside the annual count of single-family houses.
                </div>


                <div className={classes.homePageWrapper}>
                  <div className={classes.cardPageHeaderInfoWrapper}>
                    <div className={classes.cardPageTokenValue}>
                      <div className={classes.cardPageHeaderTokenTitle}>
                        Total value 2024
                        <span className={classes.cardPageHeaderTokenTitleValue}>
                          <DataItem value={cardCoin?.currentPrice} roundNum={true} startCharacter={'$'}/>
                          M
                        </span>
                      </div>
                      <div className={classes.cardPageHeaderCirculationTitle}>
                        Homes 2024
                        <span className={classes.cardPageHeaderBigTitle}>
                          <DataItem value={cardCoin?.currentCirculation} fullRound={true} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.homePageGraphWrapper}>
                    {cardCoin &&
                    <CardGraph
                      price={cardCoin.price}
                      circulation={cardCoin.circulation}
                      dates={cardCoin.dates}
                      showToolTip
                    />}
                    <div className={classes.dashboardGraphBottomTitles}>
                      <div className={classes.dashboardGraphBottomTitle}>
                        <span className={classes.dashboardGraphBottomLine}></span>
                        Total Value (in $m)
                      </div>
                      <div className={classes.dashboardGraphBottomTitle}>
                        <span className={classes.dashboardGraphBottomLine}></span>
                        Homes
                      </div>
                    </div>
                  </div>
                </div>

                <BlueLabel
                  labelClassName={classes.landingGraphLabel}
                  text={"Powered by blockchain"}
                />
                <LargeTitle
                  titleClassName={classes.landingGraphBigTitle}
                  title={"How HausCoin works"}
                />
                <div className={classes.landingGraphText}>
                  A home equity market of single family, owner occupied homes, creating a security based on appreciation
                  and powered by blockchain.
                </div>
                <MediaQuery minWidth={1180}>
                  <video
                    style={{ width: "100%" }}
                    src={coinDesktopVideo}
                    playsInline
                    autoPlay
                    muted
                    loop
                  />
                </MediaQuery>
                <MediaQuery maxWidth={1180}>
                  <video
                    style={{ width: "100%" }}
                    src={coinMobileVideo}
                    playsInline
                    autoPlay
                    muted
                    loop
                  />
                </MediaQuery>
                <div className={classes.landingGraphCardsBlock}>
                  <TextCard
                    blueTitle={"Step 1"}
                    textTitle={"We break equity into pieces"}
                    text={"Think of a home as a one large asset. Our customers own it and buy the equity they can afford up front, we purchase the rest."}
                  />
                  <TextCard
                    blueTitle={"Step 2"}
                    textTitle={"We create local pools of equity"}
                    text={"Enabled by blockchain, we pool equity in each location or area of impact."}
                  />
                  <TextCard
                    blueTitle={"Step 3"}
                    textTitle={"We issue tokens you can buy"}
                    text={"Our tokens are backed 1:1 by the value of the total pool of home equity. As values go up or down, so do the price of the tokens."}
                  />
                </div>
              </ContentContainer>
            </section>
            <section className={classes.more}>
              <ContentContainer>
                <div className={classes.moreContentWrapper}>
                  <div className={classes.moreInfoWrapper}>
                    <div className={classes.moreLogoWrapper}>
                      <img className={classes.moreLogoImg} src={lamp} alt="lamp-logo" />
                      <div className={classes.moreLogoTitle}>
                        Resources
                      </div>
                    </div>
                    <div className={classes.moreLogoTextWrapper}>
                      <LargeTitle
                        titleClassName={classes.moreLogoTextBig}
                        title={"More"}
                      />
                      <div className={classes.moreLogoTextSmall}>
                        Dig a little deeper into HausCoin
                      </div>
                    </div>
                  </div>
                  <ImgCard
                    cardClassName={classes.moreCadrImg}
                    inLineStyle={{backgroundImage: `url(${sanFrancisco})`}}
                    cardTitle={"HausCoin Brief"}
                    cardText={"Learn about why we created HausCoin, and how we are doing it."}
                    cardBtnText={"Download"}
                    cardBtnLink={"https://cdn.hauscoin.com/hauscoin_brief_2022.pdf"}
                  />
                </div>
              </ContentContainer>
            </section>
            <section className={classes.investors}>
                <Container className={classes.homePageContainer}>
                    <BlueLabel
                      labelClassName={classes.investorsLabel}
                      text={"Investors"}
                    />
                  <LargeTitle
                    titleClassName={classes.investorsTitle}
                    title={"Backed by trusted investors"}
                  />
                    <div className={classes.investorsLogos}>
                        <img className={classes.investorsLogosImg} src={investorOne} alt="investor's logo" />
                        <img className={classes.investorsLogosImg} src={investorTwo} alt="investor's logo" />
                        <img className={classes.investorsLogosImg} src={investorThree} alt="investor's logo" />
                        <img className={classes.investorsLogosImg} src={investorFour} alt="investor's logo" />
                        <img className={classes.investorsLogosImg} src={investorFive} alt="investor's logo" />
                        <img className={classes.investorsLogosImg} src={investorSix} alt="investor's logo" />
                        <img className={classes.investorsLogosImg} src={investorSeven} alt="investor's logo" />
                    </div>
                </Container>
            </section>
          <section className={classes.sliderSection}>
            <ContentContainer className={classes.homePageContainer}>
              <SliderText
                logoClassName={classes.sliderSectionLogo}
                sliderLogo={testimonials}
                sliderLogoText={"Testimonials"}
                dotsBottom={window.innerWidth <= 480 ? "-42px" : "-110px"}
                quotesColor={"#468AFF"}
                classNameSignature={"right"}
                textClassName={classes.homePageSliderText}
                signatureAuthor={"Garret Camp"}
                signatureText={"Expa"}
              />
            </ContentContainer>
            <img className={classes.sliderSectionImg} src={pyramid} alt={"Pyramid"}/>
          </section>
        </>
    );
};

export default HomePage;
